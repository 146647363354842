<template>
<div>
  <div class="show-menu-container">
    <button v-b-toggle="`admin-link-list-collapse`" class="btn btn-none btn-show-menu d-flex d-md-none align-items-center justify-content-between">
      <span v-t="activeSlug"></span>
      <i class="arrow-down-end"></i>
    </button>
    <b-collapse id="admin-link-list-collapse" class="link-list" tag="ul">
      <li v-for="link in links" :key="link.key">
        <router-link
          class="bcm-link bcm-link-secondary"
          v-t="link.key"
          :to="link.to"
        />
      </li>
      <li>
        <a
          href="#"
          class="bcm-link bcm-link-secondary"
          v-t="'logout'"
          @click.prevent="logout"
        />

      </li>
    </b-collapse>
  </div>
</div>
</template>

<script>
import { pathTranslate } from '@/helpers/routeHelpers'

export default {
  data: () => ({
    activeSlug: '',
    links: [
      {
        key: 'route.my-profile',
        to: { name: 'myProfile', params: { slug: pathTranslate({ path: 'my-profile' }) } }
      },
      {
        key: 'route.my-addresses',
        to: { name: 'myAddresses', params: { slug: pathTranslate({ path: 'my-addresses' }) } }
      },
      {
        key: 'route.my-orders',
        to: { name: 'myOrders', params: { slug: pathTranslate({ path: 'my-orders' }) } }
      },
      {
        key: 'route.my-wishlist',
        to: { name: 'myWishList', params: { slug: pathTranslate({ path: 'my-whishlist' }) } }
      },
      {
        key: 'route.my-returns',
        to: { name: 'myReturns', params: { slug: pathTranslate({ path: 'my-returns' }) } }
      },
      {
        key: 'route.my-cards',
        to: { name: 'myCards', params: { slug: pathTranslate({ path: 'my-cards' }) } }
      }
    ]
  }),
  mounted () {
    let slug = this.$route.params.slug
    let activeSlug = this.links.find((el) => {
      return el.to.params.slug === slug
    })
    this.activeSlug = activeSlug ? activeSlug.key : (this.$route.meta.title ? 'route.' + this.$route.meta.title : this.$route.name)
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
    }
  }
}
</script>
<style lang="scss" scoped>
ul{
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 15px;

    .ff-link-primary {
      &::after {
        width: 0;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &.active,
      &.router-link-active {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
</style>
